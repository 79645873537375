import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthProvider";
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function NavbarTop() {
  const { t, i18n } = useTranslation();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [navExpanded, setNavExpanded] = useState(false)
  const queryClient = useQueryClient()

  const { setAuth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();

  const changeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("de")
    } else {
      i18n.changeLanguage("en")
    }
  }

  const logout = async () => {
    await axiosPrivate.get("/auth/logout");
    queryClient.removeQueries()
    setAuth({});
    navigate('/login', { replace: true });
  }


  return (
    <div className="topnav" id="myTopnav">
      <Link to={"/"}><img class="" width="44" height="44" src="img/logo.png" /></Link>
      <div></div>
    </div>
  )
}

export default NavbarTop
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TenantProfileData from './TenantProfileData';
import TenantProfileDetail from './TenantProfileDetail';
import TenantProfileExtras from './TenantProfileExtras';
import TenantProfileRent from './TenantProfileRent';
import TenantProfileType from './TenantProfileType';
import RegisterFinal from '../../Auth/RegisterFinal';
import TenantProfileWho from './TenantProfileWho';

function TenantProfileMain() {
    const location = useLocation()
    const navigate = useNavigate();
    const [step, setStep] = useState(1)

    const next = () => {
        if (step < 7) {
            setStep((step) => step + 1)
        }
    }

    const back = () => {
        if (step > 0) {
            setStep((step) => step - 1)
        }

        if (location.pathname.includes("register")) {
            navigate("/register", { replace: true })
        }
    }

    const handleRegister = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const input = Object.fromEntries(formData)
        alert(input.email)
    }
    return (
        <div className="mt-3">
            <div className='pill-navigation'>
                <div className={step === 1 ? "pill-button active" : "pill-button"} onClick={() => setStep(1)}>Daten</div>
                <div className={step === 2 ? "pill-button active" : "pill-button"} onClick={() => setStep(2)}>Objektart</div>
                <div className={step === 3 ? "pill-button active" : "pill-button"} onClick={() => setStep(3)}>Details</div>
                <div className={step === 4 ? "pill-button active" : "pill-button"} onClick={() => setStep(4)}>Extras</div>
                <div className={step === 5 ? "pill-button active" : "pill-button"} onClick={() => setStep(5)}>Miete</div>
                <div className={step === 6 ? "pill-button active" : "pill-button"} onClick={() => setStep(6)}>Wer</div>
                <div className={step === 7 ? "pill-button active" : "pill-button"} onClick={() => setStep(7)}>Email</div>
            </div>
            <form onSubmit={handleRegister}>
                {step === 1 ?
                    <TenantProfileData /> : <></>
                }
                {step === 2 ?
                    <TenantProfileType /> : <></>
                }
                {step === 3 ?
                    <TenantProfileDetail /> : <></>
                }
                {step === 4 ?
                    <TenantProfileExtras /> : <></>
                }
                {step === 5 ?
                    <TenantProfileRent /> : <></>
                }
                {step === 6 ?
                    <TenantProfileWho /> : <></>
                }
                {step === 7 ?
                    <RegisterFinal /> : <></>
                }
                {step >= 1 && step < 7 ?
                    <div className="d-grid gap-2">
                        <button type='button' className="btn btn-primary mt-3" style={{ backgroundColor: "#335DA8" }} onClick={next}>Weiter</button>
                    </div>
                    : <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-primary mt-3" style={{ backgroundColor: "#335DA8" }}>Registrieren</button>
                    </div>
                }
            </form>
            {step > 1 ? <div className="d-grid gap-2">
                <button className="btn btn-primary mt-3" style={{ backgroundColor: "#FFF", color: "#335DA8", border: "1px solid #335DA8" }} onClick={back}>Zurück</button>
            </div> : <></>}
            {step === 0 && location.pathname.includes("register") ?
                <div className="d-grid gap-2">
                    <button className="btn btn-primary mt-3" style={{ backgroundColor: "#FFF", color: "#335DA8", border: "1px solid #335DA8" }} onClick={back}>Zurück</button>
                </div> : <></>
            }
        </div>
    )
}

export default TenantProfileMain
import React from 'react';
import { useNavigate } from 'react-router-dom';

function LandlordProfileDetail() {

    const navigate = useNavigate();
    return (
        <>
            <div className='d-grid gap-3 mt-3'>
                <div className='d-grid justify-content-start'>
                    Nutzfläche ca.
                    <div className='d-flex gap-2'>
                        <div>
                            <input type="text"
                                id="sizeMin"
                                name="sizeMin"
                                onChange={(e) => { window.sessionStorage.setItem("sizeMin", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("sizeMin")}
                            />
                        </div>
                    </div>
                    Zimmer
                    <div className='d-flex gap-2'>
                        <div>
                            <input type="text"
                                id="roomsMin"
                                name="roomsMin"
                                onChange={(e) => { window.sessionStorage.setItem("roomsMin", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("roomsMin")}
                            />
                        </div>
                    </div>
                    Etage
                    <div className='d-flex gap-2'>
                        <div>
                            <input type="text"
                                id="floorMin"
                                name="floorMin"
                                onChange={(e) => { window.sessionStorage.setItem("floorMin", e.target.value) }}
                                defaultValue={window.sessionStorage.getItem("floorMin")}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default LandlordProfileDetail